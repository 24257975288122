.activeMarker{
    color:green;
    background-color:green;
    height:10px;
    width:10px;
    right:10px;
    position:absolute;
}

.notActiveMarker{
    color:red;
    background-color:red;
    height:10px;
    width:10px;
    right:10px;
    position:absolute;
}

.switchPosition{
    height:10px;
    width:10px;
    right:25px;
    position:absolute;
}