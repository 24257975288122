.header{
    color: #ffffff;
    text-align: center;
}

.userName{
    color: #ffffff;
    text-align: center;
}

.input{
    align-items: center;  
}
.text-center{
    color: #ffffff;
}
.sendOutlinedIconPosition{
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.firstLoginButton{
    background-color: #008C9E;
}
.seconderyDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
}
.formLabel{
    color: white;
    font-size: 20;
}
.inputPosition{
    text-align: center;
}