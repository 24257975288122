.inputFormPosition {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
}
.smallHeader{
    justify-content: center;
    align-items: center;
    display: flex;
}
.downloadButtonPostion{
    display: flex;
    justify-content: right;
    align-items: right;
    padding-right: 20px;
}
.downloadJsonButton{
    color :white;
    background-color: #e09e2b;
    margin: 5;
}
.downloadCsvButton{
    color :white;
    background-color: #2ECC71;
    margin: 5;
}
.refreshModelsNamesButton{
    margin-left: 8px;
    margin-top: -15px;
    background-color: white;
    color: #1677FF;
    border-color: #1677FF;
}
.findByModelButton{
    margin-left: 8px;
    margin-top: -15px;
}