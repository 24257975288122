.deviceGpsLocationSection{
    border: 1px solid;
    border-color: #3498DB;
    border-width: 1px;
    border-radius: 5px;
    margin: 5px;
    width: 99%;
}
.deviceGpsLocationHeader{
    margin-bottom: 20px;
    padding: 5px;
}
.deviceGpsLocationMapContainer{
    margin-left: auto;
    width: 99%;
    border: 2px solid;
    border-color: #3498DB;
    border-width: 1px; 
    border-radius: 5px;
    margin: 5px;
}