.searchImeiSection {
    display: flex; 
    align-items: center;
    justify-content: center;
    padding: 10px;
    border: 1px solid #3498DB;
    border-radius: 5px;
    margin: 5px auto; 
    position: relative; 
    width: 30%;
    margin: 5px; 
    box-sizing: border-box;
}

.searchImeiActionSection {
    display: flex;
    justify-content: center;
    align-items: center; 
    gap: 10px; 
    flex: 1; 
    width: 100%; 
}

.searchImeiFindButton {
    background-color: #3498DB;
    color: white;
}

.searchImeiClearDataButton {
    background-color: red;
    color: white;
}
