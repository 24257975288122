.deviceConfigurationSection {
  border: 1px solid;
  border-color: #3498db;
  border-width: 1px;
  border-radius: 5px;
  margin: 5px;
  width: 99%;
}

.deviceConfigurationHeader {
  margin-bottom: 20px;
  padding: 5px;
}

.deviceConfigurationTableSection {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

.deviceConfigurationTable {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  table-layout: fixed; /* Ensures column width is consistent */
}

.table-wrapper {
  max-height: 400px; /* Set max height for scroll */
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Prevent horizontal scroll */
  width: 100%;
}

.deviceConfigurationTable thead {
  position: sticky;
  top: 0;
  z-index: 2; /* Ensure it stays above the rows */
  background-color: #16a085; /* Sticky header color */
}

.deviceConfigurationTable tbody {
  display: block;
  width: 100%;
}

.deviceConfigurationTable tr {
  display: table;
  width: 100%; /* Ensure rows match table width */
  table-layout: fixed;
}

.deviceConfigurationTable th,
.deviceConfigurationTable td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
  overflow-wrap: break-word; /* Prevent long text from breaking layout */
}

.table-header {
  color: white;
  text-align: left;
}

.deviceConfigurationTable tbody tr:hover {
  background-color: #f1f1f1;
}
