.deviceSelectionAndCommandContainer{
    display: flex;
    gap: 5px;
}
.deviceInformationAndGpsLocationAndConfigurationContainer {
    display: flex;
}

.leftContainer {
    display: flex;
    width: 100%;
}

.rightContainer {
    display: flex;
    width: 100%; 
}