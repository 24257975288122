/* Header styling */
.smallHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 18px; /* Adjust the size as needed */
    padding-bottom: 10px;
    gap: 80px;
}

/* Input form positioning */
.inputFormPosition {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
}

/* Outer container (ensure this doesn't override header styles) */
.outer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto; /* Use 'auto' if height is small */
    padding-top: 20px; /* Add padding to avoid clash with other elements */
}

/* Device table styling */
.device-table {
    width: 80%;
    border: 2px solid #009688;
    border-collapse: collapse;
}
.timeHeader{
    background-color: #009688;
    color: white;
    padding: 10px;
    text-align: left; 
    border-bottom: 2px solid #00796b;
    justify-content: space-between;
    align-items: center;
}

.timeHeaderButton{
    background-color: #009688;
    color: white;
    border-color: white;
    float: right;
}

/* Table headers */
.table-header {
    background-color: #009688;
    color: white;
    padding: 10px;
    text-align: left; 
    border-bottom: 2px solid #00796b;
}

/* Table cells */
.table-cell {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #009688;
}

/* Style to add vertical line in the middle */
.device-table td:not(:last-child) {
    border-right: 1px solid #009688; /* Add a line between the columns */
}
