.deviceStatusFrame{
    position: relative;
    max-width: 130px;
    padding: 10px;
    text-align: center;
    border-radius: 8px;
    border: 1px solid #1A237E;
    box-shadow: 0px 4px 8px rgba(0,0,0,0.1); 
    background-image: url(/public/mountains.png);
    background-size: cover; 
    background-position: center;
    background-repeat: no-repeat;
}