.firstDivStyle{
    max-width: 90%;
    margin: 0 auto;
}
.formStyle{
    border-width: 1px;
    border-style: solid;
    border-color: #1677FF;
    margin: 5px;
    padding: 10px;
    border-radius: 5px;
}