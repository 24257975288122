.subHeaderContainer {
    box-shadow: 0px 4px 8px rgba(0,0,0,0.1);
    background-image: url(../../public/mountains.png);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    height:130px;
    width: 100%;
  }
  .clearMapButtonContainer{
    border-color: red;
    background-color: #FE2626;
    color: white;
    right: 5px;
    position: absolute;
  }
  .clearMapButtonIcon{
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  .chooseGroupHeader{
    font-weight: bold;
    color: #2980B9;
  }
  .deviceStatusPosition{
    position: absolute;
    right: 150px;
    top: 70px;
  }
  .inputCoordinatesStyle{
    font-weight: bold;
    text-align: center;
  }
  .serachCoordinatesButtonIcon{
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  .currentGroupHeader{
    display: table;
    margin: 0 auto;
    font-weight: bold;
    color: #2980B9;
  }
  button.zoomOutToAllButton{
    width: 50px;
    top: 120px;
    left: 50px;
    padding: 0;
    border: none;
    background: transparent;
    position: absolute;
    z-index: 10;
  }
   button.searchLocationButton{
    width: 50px;
    top: 200px;
    left: 50px;
    padding: 0;
    border: none;
    background: transparent;
    position: absolute;
    z-index: 10;
  }
  