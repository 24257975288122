.cardContainer{
    border-color: #2980B9;
    margin: 5
}
.fieldHeader{
    font-weight: bold;
}
.fieldInfoText{
    font-weight: lighter;
}
.imeisField{
    display: block;
    word-wrap: break-word;
    white-space: normal;
    font-weight: lighter;
}
.deleteButtonPosition{
    position: absolute;
    top: 5;
    right: 5;
}
.ruleStatusPosition{
    position: relative;
    top: -18px;
    right: 35px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
