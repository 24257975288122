.mapLayersButton{
    width: 50px;
    height: 50px;
    top:50px;
    left: 50px;
    padding: 0;
    border: none;
    background: transparent;
}
.zoomOutToAllButton{
    width: 50px;
    height: 50px;
    top:120px;
    left:0px;
    padding: 0;
    border: none;
    background: transparent;
}
.searchLocationButton{
    width: 50px;
    height: 50px;
    top:190px;
    left:-45px;
    padding: 0;
    border: none;
    background: transparent;
}
.backgroundDiv{
    box-shadow: 0px 4px 8px rgba(0,0,0,0.1); 
    background-image: url(/public/mountains.png); 
    background-size: 100% 100%; 
    background-position: center;
    background-repeat: no-repeat;
    height: 130px;
    width: 100%;
}
