.timer{
    color: #1a237e;
}
.input{
    border: 7;
    border-color: #1a237e;
    border-radius: 25;
}
.loginButton{
    color: white;
    font-size: 15px;
    background-color: rgb(0, 140, 158);
    height: 6vh;
    width: 45%;
    border-radius: 5px;
    border-style: hidden;
}
.formLabel{
    color: white;
    font-size: 20;
    text-align: center;
}
.seonderyDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
