.deviceCommandSection {
    display: flex; 
    align-items: center; 
    padding: 10px;
    border: 1px solid #3498DB;
    border-radius: 5px;
    margin: 5px;
    position: relative;
    width: 70%; 
}

.deviceCommandHeader {
    margin: 0;
    padding: 0;
    text-align: left; 
    position: relative;
    left: 10px; 
}

.deviceCommandButtonsSection {
    display: flex;
    justify-content: center; 
    gap: 1em; 
    flex: 1; 
}
