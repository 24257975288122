.deviceMessageLog {
    border: 1px solid #3498DB;
    border-radius: 5px;
    margin: 5px;
}

.deviceMessageLogHeader {
    margin-bottom: 20px;
    padding: 5px;
}

.dateRangeContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.deviceMessageLogContainer {
    margin: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.deviceMessageLogTableWrapper {
    max-height: 300px; /* Set height of the scrollable area */
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: hidden;
}

.deviceMessageLogTable {
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
}

.deviceMessageLogTable thead {
    position: sticky;
    top: -1;
    background-color: #16A085; /* Background for sticky header */
    z-index: 2;
}

.table-header {
    color: white;
    padding: 10px;
    text-align: left;
}

.table-cell {
    padding: 10px;
    background-color: white;
}

.deviceMessageLogTable tbody tr:hover {
    background-color: #f1f1f1;
}

.deviceMessageLogTable td,
.deviceMessageLogTable th {
    border: 1px solid #ddd;
    text-align: left;
}

.deviceMessageLogTable td {
    font-size: 1em;
    color: #333;
}
