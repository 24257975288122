.deviceInformationSection{
  border: 1px solid;
  border-color: #3498DB;
  border-width: 1px;
  border-radius: 5px;
  margin: 5px;
  width: 100%;
}
.deviceInformationHeader{
  margin-bottom: 20px;
  padding: 5px; 
}
.deviceInformationContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px;
}

.deviceInformationContentHeader {
  font-weight: bold;
}

.deviceInformationContent h6 {
  font-weight: bolder;
}

.deviceInformationContent span {
  font-weight: lighter;
}