.inputFormPosition {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
}
.test{
    
    border-color: red;
    border-width: 5;
}